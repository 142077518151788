import { apiClient } from 'clients';
import {
  Agency,
  AgencySettings,
  CollectionSite,
  CollectionType,
  Operation,
  OrderingPractitioner,
  SpecimenType,
} from 'models';
import { FetchListArgs, GenericListResult, PaginationArgs } from './types';
import { handleFetchError } from 'utils/error-utils';

export interface ListByAgencyGroupArgs extends FetchListArgs, PaginationArgs {
  agencyGroupId: string;
}

export interface AgencyGroupSettingsArgs {
  id: string;
}

export interface ListByAgencyGroupAndAgencyArgs extends ListByAgencyGroupArgs {
  agencyCode: number;
}

export async function getAgenciesAsync({
  agencyGroupId,
}: ListByAgencyGroupArgs): Promise<GenericListResult<Agency>> {
  try {
    const { data } = await apiClient.get(
      `AgencyGroups/${agencyGroupId}/Agencies`,
      {
        params: { showAll: true },
      },
    );

    const { data: sourceItems } = data;

    const items = sourceItems.map(
      ({
        agencyId: id,
        agencyName: name,
        agencyCode: code,
        agencyCodeAndName: fullName,
        agencyType: type,
        isThirdParty,
      }: any) => ({
        id,
        name,
        code,
        fullName,
        type,
        isThirdParty,
      }),
    );

    return { items };
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function getAgencyGroupSettingsAsync({
  id,
}: AgencyGroupSettingsArgs): Promise<AgencySettings> {
  try {
    let agencySettings: AgencySettings = { id, allowCollectorSignature: false };

    const response = await apiClient.get(`AgencyGroups/${id}/Settings`);

    agencySettings = response.data?.data || agencySettings;
    agencySettings.id = id;

    return agencySettings;
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function getOperationsAsync({
  agencyGroupId,
}: ListByAgencyGroupArgs): Promise<GenericListResult<Operation>> {
  try {
    const { data } = await apiClient.get(
      `AgencyGroups/${agencyGroupId}/Operations`,
    );

    const { data: sourceItems } = data;

    const items = sourceItems.map(
      ({ agencyGroupId, operation: id, isEnabled: active }: any) => ({
        id,
        agencyGroupId,
        active,
      }),
    );

    return { items };
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function getCollectionSitesAsync({
  agencyGroupId,
}: ListByAgencyGroupArgs): Promise<GenericListResult<CollectionSite>> {
  try {
    const { data } = await apiClient.get(
      `AgencyGroups/${agencyGroupId}/CollectionSites`,
    );

    const { data: sourceItems } = data;

    const items = sourceItems.map(
      ({ collectionSiteId: id, siteName: name }: any) => ({
        id,
        name,
      }),
    );

    return { items };
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function getCollectionTypesAsync({
  agencyGroupId,
}: ListByAgencyGroupArgs): Promise<GenericListResult<CollectionType>> {
  try {
    const { data } = await apiClient.get(
      `AgencyGroups/${agencyGroupId}/CollectionTypes`,
    );

    const { data: sourceItems } = data;

    const items = sourceItems.map(
      ({ collectionTypeCode: id, collectionTypeName: name, active }: any) => ({
        id,
        name,
        active: active >= 1,
      }),
    );

    return { items };
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function getSpecimenTypesByAgencyAsync({
  agencyCode,
  agencyGroupId,
}: ListByAgencyGroupAndAgencyArgs): Promise<GenericListResult<SpecimenType>> {
  try {
    const { data } = await apiClient.get(
      `AgencyGroups/${agencyGroupId}/Agencies/${agencyCode}/SpecimenTypes`,
    );

    const { data: sourceItems } = data;

    const items = sourceItems.map(({ specimenType, description }: any) => {
      return {
        id: Number(specimenType),
        description,
      };
    });

    return { items };
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function getOrderingPractitionersByAgencyAsync({
  agencyCode,
  agencyGroupId,
}: ListByAgencyGroupAndAgencyArgs): Promise<
  GenericListResult<OrderingPractitioner>
> {
  try {
    const { data } = await apiClient.get(
      `AgencyGroups/${agencyGroupId}/Agencies/${agencyCode}/OrderingPractitioners`,
    );

    const { data: sourceItems } = data;

    const items = sourceItems.map(
      ({ orderingPractitionerId: id, name }: any) => ({
        id,
        name,
      }),
    );

    return { items };
  } catch (error: any) {
    throw handleFetchError(error);
  }
}
