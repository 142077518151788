import { Donor, Test } from 'models';

export enum SpecimenTypes {
  Urine = 'urine',
  Serum = 'serum',
  Oral = 'oral',
  Unknown = 'unknown',
}

export enum TestResults {
  Positive = 'positive',
  Negative = 'negative',
  Invalid = 'invalid',
  Dilute = 'dilute',
  NotTested = 'not tested',
  Unknown = 'unknown',
}

export enum TestProcedure {
  Screen = 'screen',
  Confirmation = 'confirmation',
  Unknown = 'unknown',
}

export interface Specimen {
  id: string;
  type: string;
}

export interface Result {
  id: string;
  specimenType: SpecimenTypes;

  requisitionNumber: string;
  identification: string;

  accession: string;
  accessionNum: string;
  accessionDate?: string;

  collector?: string;
  collectedDate?: string;

  reportedDate?: string;

  observed?: boolean;
  printed?: boolean;
  hasCoC?: boolean;
  hasSignature?: boolean;
  isOnsite?: boolean;

  isMobileCollection?: boolean;

  testLogicVersion: number;
  testOverallResult: string;

  donor: Donor;
  tests: (Test & { result: TestResults; procedure: TestProcedure })[];
}
