import { apiClient, ApiErrorTypes } from 'clients';
import { DateTime } from 'luxon';
import { Collection, OnSiteDeviceResultsEnum, Signature, Test } from 'models';
import { handleFetchError } from 'utils/error-utils';
import { GenericListResult, PaginationArgs } from './types';

export interface CollectionListResult extends GenericListResult<Collection> {}
export interface CollectionListArgs extends PaginationArgs {
  donorId?: number;
  requisitionNumber?: string;
  collectedSpecimenId?: number;
  startDate?: DateTime;
  endDate?: DateTime;
}

function normalizedCollection(data: any): Collection {
  const {
    requisitionNumber,
    donorId,
    collectedDate,
    collectedTime,
    agencyGroupId,
    agencyCode,
    agencyName,
    agencyAddress1,
    agencyAddress2,
    agencyAddress3,
    agencyCity,
    agencyState,
    agencyZipCode,
    agencyPhone,
    collectorFirstName,
    collectorLastName,
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    donorPhone,
    sex,
    siteNameId,
    testReasonId,
    testReason,
    observed,
    specimenTypeId,
    specimenType,
    temperature,
    uniqueId,
    testRequestedList,
    hasSignature,
    orderingPractitionerId,
    specimenId,
    remarks,
    isMobileCollection,
  } = data;
  return {
    id: requisitionNumber,
    requisitionNumber,
    collectedDate,
    collectedTime,
    donorId,
    agencyId: agencyCode,
    agencyGroupId,
    agencyName,
    agencyAddress1,
    agencyAddress2,
    agencyAddress3,
    agencyCity,
    agencyState,
    agencyZipCode,
    agencyPhone,
    collectorFirstName,
    collectorLastName,
    donorFirstName: firstName,
    donorMiddleName: middleName,
    donorLastName: lastName,
    donorBirthday: dateOfBirth,
    donorPhone: donorPhone,
    donorSex: sex,
    collectionSiteId: siteNameId,
    collectionType: testReasonId,
    collectionTypeName: testReason,
    observed,
    specimenType: specimenTypeId,
    specimenTypeName: specimenType,
    temperature,
    uniqueId,
    tests: (testRequestedList && Array.isArray(testRequestedList)
      ? testRequestedList.map(({ drug, drugAbbreviaton, testCode, tests }) => ({
          description: tests,
          abbreviation: drugAbbreviaton,
          code: testCode,
          drug: drug,
        }))
      : []) as Test[],
    signature: hasSignature ? ({} as Signature) : null,
    hasSignature,
    source: isMobileCollection ? 'MOBILE' : 'TA',
    orderingPractitionerId,
    specimenId,
    collectorRemarks: remarks,
  } as Collection;
}

function normalizedResults(items: any[] = []): Collection[] {
  return items.map((item) => normalizedCollection(item));
}

export async function getCollectedCollectionsTodayAsync(
  args: CollectionListArgs,
): Promise<CollectionListResult> {
  return getCollectedCollectionsAsync({
    ...args,
    startDate: DateTime.now(),
  });
}

export async function getCollectedCollectionsAsync(
  args: CollectionListArgs,
): Promise<CollectionListResult> {
  try {
    const params: any = {
      page: args.page || 1,
      rowsPerPage: args.pageSize || 25,
    };
    if (args.donorId) {
      params.donorId = args.donorId;
    }
    if (args.requisitionNumber) {
      params.requisitionNumber = args.requisitionNumber;
    }
    if (args.collectedSpecimenId) {
      params.collectedSpecimenId = args.collectedSpecimenId;
    }

    if (args.startDate) {
      params.startDate = args.startDate.toFormat('MM/dd/yyyy');
    }

    if (args.endDate) {
      params.endDate = args.endDate.toFormat('MM/dd/yyyy');
    }

    const { data } = await apiClient.get('/Collections', {
      params: params,
    });

    const {
      collectionList,
      page,
      rowsPerPage: pageSize,
      totalOfRows: totalCount,
    } = data;

    const items = normalizedResults(collectionList);

    return {
      items,
      meta: {
        page,
        pageSize,
        totalCount,
      },
    };
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function isRequisitionNumberUsed(
  requisitionNumber: string,
): Promise<boolean> {
  let isUsed = false;
  try {
    await apiClient.head(`/collections/${requisitionNumber}`);
    isUsed = true;
  } catch (error: any) {
    if (error?.code === ApiErrorTypes.Forbidden) {
      isUsed = true;
    } else if (error?.code === ApiErrorTypes.NotFound) {
      isUsed = false;
    } else {
      throw error;
    }
  }
  return isUsed;
}

export async function saveCollection(
  collection: Partial<Collection>,
): Promise<Collection> {
  const {
    donorId,
    temperature,
    collectionType,
    agencyId,
    requisitionNumber,
    specimenType,
    collectionSiteId,
    observed,
    orderingPractitionerId,
    typeOfTest,
    collectorRemarks,
    deviceId,
    deviceResults,
    hasAdditionalTest,
    tests,
    isScheduledCollection,
    scheduledCollectionDetail,
    collectorId,
  } = collection;

  const isOnsite = typeOfTest === 'OnSite';

  const payload: any = {
    donorId,
    temperature,
    collectionType,
    agencyCode: agencyId,
    specimenType,
    observed,
    isOnsite,
    hasAdditionalTest,
    donorSignature: {
      signatureBase64: collection.donorSignature?.signatureBase64
        ? collection.donorSignature?.signatureBase64
        : '',
    },
    collectorSignature: {
      signatureBase64: collection.collectorSignature?.signatureBase64
        ? collection.collectorSignature?.signatureBase64
        : '',
    },
    clientCreatedDatetime: DateTime.now().toUTC().toISO(),
    source: 'Mobile',
    isScheduledCollection,
    ...(!!isScheduledCollection && {
      scheduledCollectionDetail,
    }),
  };

  if (collectorId) payload.collectorId = collectorId;

  if (collectionSiteId) payload.collectionSiteId = collectionSiteId;

  if (orderingPractitionerId)
    payload.orderingPractitionerId = orderingPractitionerId;

  if (collectorRemarks) payload.remarks = collectorRemarks;

  if (isOnsite) {
    payload.onsiteData = {
      deviceId,
      testResults: deviceResults?.map(({ code, result }) => ({
        testCode: code,
        testResult: OnSiteDeviceResultsEnum[result],
      })),
    };

    if (hasAdditionalTest) {
      payload.requisitionNumber = requisitionNumber;
      payload.tests = tests?.map(({ code }) => ({ testCode: code }));
    }
  } else {
    payload.requisitionNumber = requisitionNumber;
    payload.tests = tests?.map(({ code }) => ({ testCode: code }));
  }

  try {
    const { data } = await apiClient.post(`/collections`, payload);
    return {
      ...collection,
      specimenId: data?.data?.collectionOnsiteSavedViewModel?.specimenId,
      id: requisitionNumber,
    } as Collection;
  } catch (error: any) {
    throw handleFetchError(error);
  }
}

export async function logCollection(
  collection: Partial<Collection>,
  agencyGroupId: String,
) {
  const {
    donorId,
    temperature,
    collectionType,
    agencyId,
    requisitionNumber,
    specimenType,
    collectionSiteId,
    observed,
    orderingPractitionerId,
    tests,
  } = collection;

  const payload: any = {
    donorId,
    temperature,
    collectionType,
    requisitionNumber,
    agencyCode: agencyId,
    specimenType,
    collectionSiteId: collectionSiteId || 0,
    observed,
    orderingPractitionerId: orderingPractitionerId || 0,
    tests: tests?.map(({ code }) => ({ testCode: code })),
  };

  try {
    const { data } = await apiClient.post(
      `Logger/AgencyGroups/${agencyGroupId}/Collections`,
      payload,
    );
    return data;
  } catch (error: any) {}
}
